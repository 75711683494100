import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import Sticky from 'sticky-js';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import Tutorials from './pages/Tutorials';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Documentation from './pages/Documentation';
import Help from './pages/Support';
import PageNotFound from './pages/PageNotFound';
import DriveFolderCopy from './pages/DriveFolderCopy';
import { SessionProvider } from './utils/Session';
import PrivacyPolicy from './pages/Privacy';
import Tos from './pages/Tos';
import JoinWaitlist from './pages/JoinWaitlist';
import Kbee from './pages/Kbee';

function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <SessionProvider>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/tutorials">
          <Tutorials />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/blog-post">
          <BlogPost />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/documentation">
          <Documentation />
        </Route>
        <Route path="/support">
          <Help />
        </Route>
        <Route path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/tos">
          <Tos />
        </Route>
        <Route path="/join-waitlist">
          <JoinWaitlist />
        </Route>
        <Route path="/copy-folder">
          <DriveFolderCopy />
        </Route>
        <Route path="/kbee">
          <Kbee />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </SessionProvider>
  );
}

export default App;
