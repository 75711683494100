import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gapi } from 'gapi-script'
import Transition from '../utils/Transition';
import GooglePicker from 'react-google-picker'

const clientId = '1088303620144-dv3ssarfvqfhq20lqia0e1r6ib2r9c1f.apps.googleusercontent.com'
const apiKey = 'AIzaSyBT54gN7h9TN4PV2Photdgi6sWMtjnQqN8'

export default function FolderCopy() {
  const [sourceFolder, setSourceFolder] = useState({ notSelected: true })
  const [destFolder, setDestFolder] = useState({ notSelected: true })
  const [folderNested, setFolderNested] = useState(false)

  function reset() {
    setSourceFolder({ notSelected: true })
    setDestFolder({ notSelected: true })
  }

  useEffect(() => {
    setFolderNested(!!destFolder?.breadcrumbs?.find(e => e === sourceFolder.id) || sourceFolder.id === destFolder.id)
  }, [destFolder, sourceFolder])

  return <>
    <FolderSelect title='Folder to Copy' btnTitle='Selected' setFolder={setSourceFolder} folder={sourceFolder} />
    <br />
    <FolderSelect title='Destination' btnTitle='Destination' setFolder={setDestFolder} folder={destFolder} allowHome />
    <br />
    <Transition
      show={!sourceFolder.notSelected && !destFolder.notSelected && destFolder.id && sourceFolder.id}
      appear={true}
      className="m-auto justify-center flex max-w-xl flex-wrap"
      enter="transition ease-in-out duration-700 transform order-first"
      enterStart="opacity-0 translate-x-16"
      enterEnd="opacity-100 translate-x-0"
      leave="transition ease-in-out duration-300 transform absolute"
      leaveStart="opacity-100 translate-x-0"
      leaveEnd="opacity-0 -translate-x-16"
    >
      {folderNested ?
        <h4 className='h4 text-gray-800 text-center'>Looks like you are trying to copy a folder into itself.<br />Please change the destination or the selected folder and try again!</h4> :
        <FolderCloner sourceFolder={sourceFolder} destFolder={destFolder} reset={reset} />
      }
    </Transition>
    <br />
  </>
}

function FolderSelect({ folder, setFolder, title, btnTitle, allowHome }) {

  const [folderName, setFolderName] = useState(null)
  const [active, setActive] = useState(false)

  useEffect(() => {
    setFolderName(folder.name ?? null)
  }, [folder])

  let buttonName = folderName ? `${btnTitle}: ${folderName}` : `Select ${title}`
  if (allowHome) buttonName = 'Choose Folder'

  const picker = <GooglePicker clientId={clientId}
    developerKey={apiKey}
    scope={['https://www.googleapis.com/auth/drive']}
    onAuthFailed={data => console.log('on auth failed:', data)}
    createPicker={(google, oauthToken) => {
      const googleViewId = google.picker.ViewId.FOLDERS;
      const docsView = new google.picker.DocsView(googleViewId)
        .setIncludeFolders(true)
        .setMimeTypes('application/vnd.google-apps.folder')
        .setParent('root')
        .setSelectFolderEnabled(true);

      const picker = new window.google.picker.PickerBuilder()
        .addView(docsView)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(apiKey)
        .hideTitleBar()
        .setMaxItems(1)
        .setCallback(async data => {
          console.log('on change:', data)
          if (data.action === 'picked') {
            setFolder({})
            const folder = data?.docs?.[0] ?? {}
            folder.breadcrumbs = await docToBreadcrumb(folder.id)
            folder.notSelected = false
            setFolder(folder)
            setActive(false)
          }
        });

      picker.build().setVisible(true);
    }}
  >
    <button className={`btn ${folderName && !allowHome ? 'bg-gray-100 hover:bg-gray-200' : 'text-white bg-blue-600 hover:bg-blue-700'}`} >{buttonName}</button>
  </GooglePicker>

  return <div className="flex flex-col">
    <div className="overflow-hidden max-w-3xl mx-auto align-middle">
      <div className="py-2 align-middle inline-block md:min-w-large sm:px-6 lg:px-8">
        <div className="w-full justify-center flex">
          {allowHome ? <>
            {!active ? <button className={`btn ${folderName ? 'bg-gray-100 hover:bg-gray-200' : 'text-white bg-blue-600 hover:bg-blue-700'}`} onClick={() => setActive(true)}>{folderName ? `${btnTitle}: ${folderName}` : `Select ${title}`}</button>
              : <div className='flex'>
                <button className={`btn text-white bg-teal-600 hover:bg-teal-700`} onClick={() => {
                  setFolder({ id: 'root', breadcrumbs: [], notSelected: false, name: 'Home' })
                  setActive(false)
                }}>Use Home Folder</button>
                <span style={{ width: '10px' }} />
                {picker}
              </div>}
          </>
            : picker}
        </div>
      </div>
    </div>
  </div >

}

function FolderCloner({ sourceFolder, destFolder, reset }) {

  const emitter = useRef()
  const [rerender, setRerender] = useState(0)
  const [copiedFolderId, setCopiesFolderId] = useState('')

  if (!emitter.current) {
    window.total = 0
    window.complete = 0
    emitter.current = {
      emit: (name) => {
        if (name === 'total') window.total++
        else window.complete++
        setRerender(Math.random())
      }
    }
  }


  const cloneFolder = useCallback(async (from, to, original) => {

    let name = from.name

    if (original) {
      if (document.getElementById('newName').value) name = document.getElementById('newName').value
      else name = 'Copy of ' + from.name
    }

    // Create new folder
    emitter.current.emit('total')
    const newFolder = (await gapi.client.drive.files.create({
      resource: {
        name,
        mimeType: 'application/vnd.google-apps.folder',
        parents: [to.id]
      }
    })).result
    if (original) setCopiesFolderId(newFolder.id)
    emitter.current.emit('complete')

    /*const folderMessage = document.createElement('div')
    folderMessage.textContent = `Folder ${from.name} Copied`
    output.append(folderMessage)*/

    const folders = (await gapi.client.drive.files.list({
      q: `'${from.id}' in parents and mimeType = 'application/vnd.google-apps.folder' and trashed = false`,
      pageSize: 100,
      fields: 'nextPageToken, files(id, name)'
    })).result.files

    folders.forEach(() => emitter.current.emit('total'))
    folders.map(async folder => {
      await cloneFolder(folder, newFolder, false)
      emitter.current.emit('complete')
    })

    const files = (await gapi.client.drive.files.list({
      q: `'${from.id}' in parents and mimeType != 'application/vnd.google-apps.folder' and trashed = false`,
      pageSize: 1000,
      fields: 'nextPageToken, files(id, name)'
    })).result.files

    // TODO: code to itterate through if there are more than 100 files
    files.forEach(() => emitter.current.emit('total'))
    files.map(async file => {
      // Create Copy of File
      const cloned = (await gapi.client.drive.files.copy({
        fileId: file.id
      })).result

      // Move copy to new folder
      await gapi.client.drive.files.update({
        fileId: cloned.id,
        addParents: newFolder.id,
        removeParents: from.id,
        resource: { name: file.name },
        fields: 'id, parents'
      })
      emitter.current.emit('complete')
      /*const fileMessage = document.createElement('div')
      fileMessage.textContent = `File ${file.name} Copied`
      output.append(fileMessage)*/

    })
  }, [])

  if (rerender > 0 && window.total === window.complete) return <Transition
    show={true}
    appear={true}
    enter="transition ease-in-out duration-1000 transform order-first"
    enterStart="opacity-0 translate-y-16"
    enterEnd="opacity-100 translate-y-0"
    leave="transition ease-in-out duration-100 transform absolute"
    leaveStart="opacity-100 translate-y-0"
    leaveEnd="opacity-0 -translate-y-16"
  >
    <div className='flex flex-col'>
      <h4 className='h4 text-gray-800 text-center w-full'>Folder copied!</h4>
      <a href={`https://drive.google.com/drive/folders/${copiedFolderId}`} className='btn text-white bg-teal-600 hover:bg-teal-700 my-1' rel="nofollow noopener noreferrer" target="_blank">View Folder</a>
      <button className='btn text-white bg-blue-600 hover:bg-blue-700 my-1' onClick={reset}>Copy Another</button>
      <div className='flex flex-col'>
        <h5 className='pt-8'><b>New:</b> Build a professional help center straight from Google Drive</h5>
        <button className='btn text-white bg-yellow-600 hover:bg-yellow-700 my-1' onClick={reset}>Try it!</button>
      </div>
    </div>
  </Transition>
  return <>{rerender > 0 ? <>
    <div className="w-full">
      <div className="h-3 relative rounded-full overflow-hidden">
        <div className="w-full h-full bg-gray-200 absolute"></div>
        <div className="h-full bg-green-500 absolute" style={{ width: `${Math.floor((window.complete / window.total) * 100)}%` }}></div>
      </div>
    </div>
    <button className='btn text-white bg-red-500 hover:bg-red-600 mt-3' onClick={() => {
      alert('Folders and Files that have already been copied will not be removed.')
      window.location.reload()
    }}>Stop Copy</button>
  </> : <div style={{ display: 'contents' }}>
      <input id="newName" className="form-input w-9/12 text-gray-800 px-3 py-2 pr-12 text-sm mb-5" placeholder={`New Folder Name: Copy of ${sourceFolder.name}`} />
      <button className='btn text-white bg-red-500 hover:bg-red-600' onClick={() => cloneFolder(sourceFolder, destFolder, true)}>Start Copy</button>
    </div>
  }
  </>
}

async function getDoc(docId) {
  const result = (await gapi.client.drive.files.get({
    fileId: docId,
    fields: 'id, parents'
  })).result
  return docToBreadcrumb(result.parents?.[0] ?? 'root')
}
async function docToBreadcrumb(id) {
  if (id === 'root') return ['root']
  return [...await getDoc(id), id]
}