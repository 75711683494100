import React from 'react';
import { Link } from 'react-router-dom';

export const features = [{
  main: 'Copy Drive Folders',
  sub: <>Wish you could copy full folders in Google Drive? <br /> Now you can. 100% free!</>,
  link: '/copy-folder',
  image: <><video className="md:max-w-none mx-auto rounded" src={require('../images/cf.mp4')} width="500" alt="Features bg" loop autoPlay muted />
    <div className="max-w-3xl mx-auto text-center p-2">
      <Link to='/copy-folder' className="btn text-white bg-blue-600 hover:bg-blue-700">Try it now</Link>
    </div>
  </>
},
{
  main: 'Help Center',
  sub: 'Build a fully-featured help center using Google Docs.',
  link: '/kbee',
  image: <>
    <video className="md:max-w-none mx-auto rounded" src={require('../images/d2w.mp4')} width="500" alt="Features bg" loop autoPlay muted />
    <div className="max-w-3xl mx-auto text-center p-2">
      <Link to='/kbee' className="btn text-white bg-blue-600 hover:bg-blue-700">Try it now</Link>
    </div>
  </>
},
{
  main: 'Organize and Categorize',
  sub: 'Categorize unstructured text like qualitative feedback, support emails and more. Directly in Google Docs.',
  link: '/join-waitlist?feature=auto-categorize',
  image: <>
    <video className="md:max-w-none mx-auto rounded" src={require('../images/cat.mp4')} width="500" alt="Features bg" loop autoPlay muted />
    <div className="max-w-3xl mx-auto text-center p-2">
      <Link to='/join-waitlist?feature=auto-categorize' className="btn text-white bg-blue-600 hover:bg-blue-700">Join Waitlist</Link>
    </div>
  </>
},
{
  main: 'Automatic Project Management',
  sub: 'Are you collaborating, planning, and assigning tasks in Google Docs or Sheets? Automatically create a fully interactive project plan without any additional tools.',
  link: '/join-waitlist?feature=project-management',
  image: <>
    <video className="md:max-w-none mx-auto rounded" src={require('../images/apm.mp4')} width="500" alt="Features bg" loop autoPlay muted />
    <div className="max-w-3xl mx-auto text-center p-2">
      <Link to='/join-waitlist?feature=project-management' className="btn text-white bg-blue-600 hover:bg-blue-700">Join Waitlist</Link>
    </div>
  </>
},
]