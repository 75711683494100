import React, { useState, useEffect, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import { gapi } from 'gapi-script'
import { useHistory } from 'react-router'

const clientId = '1088303620144-dv3ssarfvqfhq20lqia0e1r6ib2r9c1f.apps.googleusercontent.com'
const apiKey = 'AIzaSyBT54gN7h9TN4PV2Photdgi6sWMtjnQqN8'
const discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]
const scope = 'profile email https://www.googleapis.com/auth/drive'

const sessionContext = createContext()

export function SessionProvider({ children }) {
  const auth = useProvideAuth()
  return <sessionContext.Provider value={auth}>{children}</sessionContext.Provider>
}
SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSession = () => {
  return useContext(sessionContext)
}


function useProvideAuth() {
  const history = useHistory()
  const [user, setUser] = useState(null)
  const [switchLoginState, setSwitchLoginState] = useState([])
  const [lgapi, setGapi] = useState(null)

  useEffect(() => {
    gapi.load('client:auth2', initClient)
    async function initClient() {
      await gapi.client.init({ apiKey, clientId, discoveryDocs, scope, ux_mode: 'redirect', redirect_uri: `https://${window.location.host}` })
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus)
      // Handle the initial sign-in state.
      console.log(gapi.auth2.getAuthInstance().isSignedIn.get())
      updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
    }

    function signIn() {
      sessionStorage.setItem('redirect', window.location.pathname)
      gapi.auth2.getAuthInstance().signIn()
    }

    function updateSigninStatus(isSignedIn) {
      if (isSignedIn) {
        const user = gapi.auth2.getAuthInstance().currentUser.get()
        const displayName = user.getBasicProfile().getName()
        const email = user.getBasicProfile().getEmail()
        if (window.FS) window.FS.identify(email, { displayName, email })
        setUser(user)
        setSwitchLoginState([gapi.auth2.getAuthInstance().signOut])
        setGapi(gapi)
        const redirect = sessionStorage.getItem('redirect')
        if (redirect) {
          sessionStorage.removeItem('redirect')
          history.replace(redirect)
        }
      } else {
        setUser(null)
        setSwitchLoginState([signIn])
        setGapi(null)
      }
    }
  }, [history])

  // Return the user object and auth methods
  return {
    user,
    setUser,
    switchLoginState,
    gapi: lgapi,
  }
}