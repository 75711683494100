import React from 'react';
import { useSession } from '../utils/Session';

export default function SignIn() {
  const session = useSession()
  return <div className="flex flex-wrap -mx-3">
    <div className="w-full px-3">
      {!session.switchLoginState?.[0] ? <button className="btn p-0 flex animate-pulse m-auto" style={{ width: '191px', backgroundColor: '#4285f4', height: '46px' }}></button> :
        <button className="btn p-0 flex m-auto" onClick={() => session.switchLoginState[0]()}>
          <img className="md:max-w-none mx-auto rounded" src={require('../images/btn_google_signin_dark_normal_web.png')} alt="Google Sign In" />
        </button>
      }
    </div>
  </div>
}