import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

export default function JoinWaitlist() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-2xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-6 md:pb-12">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-2 md:pb-4">
                <h2 className="h2">Join the Google Drive <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-orange-400">Revolution</span></h2>
              </div>
              <div className="max-w-3xl mx-auto text-center">
                Get early access to the most powerful collection of tools avaliable for Google Drive.
                  <form className="w-full mt-4" name="subscribe" method="POST">
                  <label className="block text-sm sr-only" htmlFor="newsletter">Email</label>
                  <div className="relative flex items-center max-w-xs m-auto">
                    <input id="newsletter" name="email" type="email" className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm" placeholder="Your email" required />
                    <input name="source" type="hidden" value={(new URLSearchParams(window.location.search)).get('feature') || 'waitlist-page'} />
                    <input name="form-name" type="hidden" value="subscribe" />
                    <button type="submit" className="absolute inset-0 left-auto" aria-label="Subscribe">
                      <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                      <svg className="w-3 h-3 fill-current text-blue-600 mx-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/*  Site footer */}
      <Footer />

    </div>
  );
}