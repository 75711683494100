import React from 'react';
import Accordion from '../utils/Accordion';

export default function Faqs({ title, content }) {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">

          {/* Section header */}
          {title && <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">{title}</h2>
          </div>}

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            {content?.map(e => <Accordion title={e.title} key={e.title}>{e.content}</Accordion>)}
            <span className="block border-t border-gray-200" aria-hidden="true"></span>
          </ul >

        </div >
      </div >
    </section >
  );
}
