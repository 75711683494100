import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { useSession } from '../utils/Session';
import SignIn from '../partials/Singin';
import FolderCopy from '../partials/FolderCopy';
import Faqs from '../partials/Faqs';

export default function DriveFolderCopy() {
  const session = useSession()
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-2xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-6 md:pb-12">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-2 md:pb-4">
                <h2 className="h2">Copy <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Folders and Files</span> in Drive</h2>
              </div>
              <div className="max-w-3xl mx-auto text-center">
                Need to copy a folder in Drive? Now you can! This tool will automatically copy all documents and subfolders within the folder with just a few clicks.
              </div>
              {!session.user && <div className="pt-12 md:pt-20"><SignIn /></div>}
            </div>
          </div>
          <div className="mx-auto px-4 sm:px-6">
            {session.user && <FolderCopy />}
          </div>
        </section>
      </main>
      <Faqs content={[{
        title: 'Is this free?',
        content: 'Yup, 100% free! Check out some of our other tools if you want to do even more with Google Drive!'
      }, {
        title: 'Is this secure?',
        content: 'Your files never leave Google Drive. We do not download or read any of your data. This tool uses the Google Drive API to automate the manual copy and move process.'
      },
      {
        title: 'Is there a limit?',
        content: 'Google Drive limits you to 750GB of uploads and new file creation per day. Copying files and folders counts as part of that.'
      }]} />
      {/*  Site footer */}
      <Footer />

    </div>
  );
}